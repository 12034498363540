<div class="modal-heading">
  <div class="modal-close-btn">
    <i type="button" aria-label="Close" class="bi bi-x-lg" (click)="onSubmit('CLOSE')"></i>
  </div>
  <div class="row">
    <!-- <div class="modal-title f-20">Select the number of days required</div> -->
    <div class="modal-title f-20">{{content.title}}</div>
  </div>
</div>

<div class="modal-body">
  <div>
    <div class="f-18 bold">{{content.subContent1.website}}</div>
    <ul>
      <li>{{content.subContent1.basicWebsite}}</li>
      <li>{{content.subContent1.dynamicWebsite}}</li>
      <li>{{content.subContent1.eCommerceWebsite}}</li>
      <li>{{content.subContent1.customWebApplication}}</li>
    </ul>
  </div>
  <div>
    <div class="f-18 bold">{{content.subContent2.mobileApp}}</div>
    <ul>
      <li>{{content.subContent2.simpleMobileApp}}</li>
      <li>{{content.subContent2.mediumComplexityMobileApp}}</li>
      <li>{{content.subContent2.complexMobileApp}}</li>
    </ul>
  </div>
  <div>
    <div class="f-18 bold">{{content.subContent3.cRMSystem}}</div>
    <ul>
      <li>{{content.subContent3.basicCRMSystem}}</li>
      <li>{{content.subContent3.mediumComplexityCRMSystem}}</li>
      <li>{{content.subContent3.customizedCRMSystem}}</li>
    </ul>
  </div>
  <div>
    <div class="f-18 bold">{{content.subContent4.eRPSystem}}</div>
    <ul>
      <li>{{content.subContent4.smallBusinessERPSystem}}</li>
      <li>{{content.subContent4.mediumComplexityERPSystem}}</li>
      <li>{{content.subContent4.largeEnterpriseERPSystem}}</li>
    </ul>
  </div>
  <div class="note-text">{{content.pleaseNoteThatText}}</div>
  <div style="margin-left: 20px;">
    <p style="font-size: 15px;">
      <b>{{content.featuresAndFunctionality}}</b>
      {{content.complexityAndNumberOfFeatures}}
    </p>
    <p style="font-size: 15px;">
      <b>{{content.designAndUserExperience}}</b>
      {{content.designingAUserFriendlyInterface}}
    </p>
    <p style="font-size: 15px;">
      <b>{{content.integration}}</b>
      {{content.ifTheProjectNeedsIntegrate}}
    </p>
    <p style="font-size: 15px;">
      <b>{{content.testingAndQualityAssurance}}</b>
      {{content.properTestingAndQualityAssurance}}
    </p>
    <p style="font-size: 15px;">
      <b>{{content.platformAndTechnology}}</b>
      {{content.chosenPlatformFrameworksLibrariesTechnologies}}
    </p>
    <p style="font-size: 15px;">
      <b>{{content.teamExperience}}</b>
      {{content.anExperiencedDevelopmentTeam}}
    </p>
    <p style="font-size: 15px;">
      <b>{{content.projectManagement}}</b>
      {{content.effectiveProjectManagement}}
    </p>
    <p style="font-size: 15px;">
      <b>{{content.customization}}</b>
      {{content.projectsRequiringHighLevelsOfCustomization}}
    </p>
  </div>
</div>

<div class="modal-foot">
  <button type="button" class="btn btn-primary f-16 rounded-2 w-100" (click)="onSubmit('CONFIRM')">
    {{ content.okBtn }}
  </button>
</div>