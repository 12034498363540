import { Component, Input, OnInit } from '@angular/core';
import { PopupModalEventAtion } from '../popup-facade.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  isDiscounted: boolean = false;
  contactForm!: FormGroup;
  language: any;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      'name': ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.pattern('^[a-zA-Z ]*$')
      ]],
      'contactNumber': ['', [
        Validators.required,
        Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')
      ]],
      'emailAddress': ['', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]],
      'businessName': ['', [
        Validators.minLength(5),
        Validators.maxLength(50)
      ]],
      'projectDesc': ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100)
      ]],
    });
  }

  onSubmit(eventAction: PopupModalEventAtion): void {
    if (eventAction === 'CLOSE') {
      this.activeModal.close({
        popupModalEventAtion: eventAction,
      });
    } else {
      if (this.contactForm.valid) {
        this.activeModal.close({
          popupModalEventAtion: eventAction,
          data: this.contactForm.value
        });

        console.log(this.contactForm.value);
      }
    }
  }

}
