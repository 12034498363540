import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Category } from 'src/app/models/category/category.model';
import { Summary } from 'src/app/models/quotation/summary.model';
import { PopupFacadeService } from 'src/app/popup-facade/popup-facade.service';

@Component({
  selector: 'app-cost-table',
  templateUrl: './cost-table.component.html',
  styleUrls: ['./cost-table.component.scss'],
  providers: [CurrencyPipe]
})
export class CostTableComponent {
  @Input() dataSet: Category[] = []
  @Input() language: any;
  @Input('hours') no_of_hours: number = 0;
  @Output() moveCategory: EventEmitter<Category> = new EventEmitter<Category>();
  @Output() summary: EventEmitter<Summary> = new EventEmitter<Summary>();
  @Output() isDiscounted: EventEmitter<boolean> = new EventEmitter<boolean>();
  discount: number = 0;
  isChecked: boolean = false;
  
  constructor(
    private popupFacadeService: PopupFacadeService
  ) { }

  isCategoryAvailable(category: any): boolean {
    console.log(category);
    return category && category.length > 0;
  }

  decrementQuantity(mainCategory: any, positionCategory: any, languageCategory: any) {
    if (positionCategory.qty > 1) {
      positionCategory.qty--;
    } else {
      positionCategory.is_selected = false;
      this.checkAndRemoveEmptyCategories(mainCategory);
    }
  }

  decrementDays(mainCategory: any, positionCategory: any, languageCategory: any) {
    this.popupFacadeService.showDaysSelectionPopup(this.language, (data: any) => {
      if (data) {
        positionCategory.no_of_days = data;
      }
    }, positionCategory.no_of_days);
  }

  checkAndRemoveEmptyCategories(mainCategory: any) {
    for (const mainCat of this.dataSet) {
      let isAnyPositionActive = false; // Declare and reset for each main category

      for (const stackCategory of mainCat.stack_categories) {
        for (const languageCategory of stackCategory.language_categories) {
          for (const positionCategory of languageCategory.position_categories) {
            if (positionCategory.is_selected) {
              isAnyPositionActive = true;
              break;
            }
          }
          if (isAnyPositionActive) {
            break;
          }
        }
        if (isAnyPositionActive) {
          break;
        }
      }

      if (!isAnyPositionActive) {
        console.log("No active positions in the main object.");
        this.moveCategory.emit(mainCat);
      }
    }
  }

  incrementQuantity(positionCategory: any) {
    positionCategory.qty++;
  }

  incrementDays(positionCategory: any) {
    this.popupFacadeService.showDaysSelectionPopup(this.language, (data: any) => {
      if (data) {
        positionCategory.no_of_days = data;
      }
    }, positionCategory.no_of_days);
  }

  calculateTotal(positionCategory: any) {
    return positionCategory.qty * positionCategory.no_of_days * positionCategory.price.amount * this.no_of_hours;
  }

  calculateCostAndDate(): { totalCost: number, maximumDate: number } {
    let totalCost = 0;
    let maximumDate = 0;

    for (const mainCategory of this.dataSet) {
      for (const stackCategory of mainCategory.stack_categories) {
        for (const languageCategory of stackCategory.language_categories) {
          for (const positionCategory of languageCategory.position_categories) {
            if (positionCategory.is_selected) {
              totalCost += this.calculateTotal(positionCategory);
              maximumDate = Math.max(maximumDate, positionCategory.no_of_days);
            }
          }
        }
      }
    }

    if (this.isChecked) {
      totalCost = totalCost - ((totalCost * this.discount) / 100);
    }

    const final: Summary = {
      total_amount: totalCost,
      total_days: maximumDate
    }
    this.summary.emit(final);
    return { totalCost, maximumDate };
  }

  isCategorySelected(mainCategory: any): boolean {
    for (const stackCategory of mainCategory.stack_categories) {
      for (const languageCategory of stackCategory.language_categories) {
        for (const positionCategory of languageCategory.position_categories) {
          if (positionCategory.is_selected) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isPositionSelected(positionCategory: any): boolean {
    return positionCategory.is_selected;
  }

  isLanguageCategorySelected(languageCategory: any): boolean {
    for (const positionCategory of languageCategory.position_categories) {
      if (this.isPositionSelected(positionCategory)) {
        return true;
      }
    }
    return false;
  }

  isStackCategorySelected(stackCategory: any): boolean {
    for (const languageCategory of stackCategory.language_categories) {
      if (this.isLanguageCategorySelected(languageCategory)) {
        return true;
      }
    }
    return false;
  }

  toggleDiscount(): void {
    this.isChecked = !this.isChecked;
    this.isDiscounted.emit(this.isChecked);
    this.isChecked ?  this.discount = 10 : this.discount = 0;
  }

}
