import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent {
  @Input() language: any;
  @Input() isSaveBtnDisabled = true;
  @Input() isFinalizeBtnDisabled = true;
  @Output('onSaveClick') onSaveClick = new EventEmitter();
  @Output('onFinalizeClick') onFinalizeClick = new EventEmitter();

  saveQuotation() {
    this.onSaveClick.emit()
  }

  finalizeQuotation() {
    this.onFinalizeClick.emit()
  }
}
