import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PopupFacadeService } from 'src/app/popup-facade/popup-facade.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() languageChanged = new EventEmitter<string>();
  @Input() isSwSelected: boolean = false;
  @Input() language: any;
  showButtons: boolean = true;
  showDropdown: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private popupFacadeService: PopupFacadeService
  ) { }

  toggleLanguage() {
    this.isSwSelected = !this.isSwSelected;
    const selectedLanguage = this.isSwSelected ? 'sw' : 'en';
    this.languageChanged.emit(selectedLanguage);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showDropdown = false; // Close dropdown when clicking outside
    }
  }

  toggleButtons() {
    this.showButtons = !this.showButtons;
    this.showDropdown = false;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  callPhoneNumber(): void {
    window.location.href = 'tel:+46730445855';
  }

  sendEmail(): void {
    window.location.href = 'mailto:hej@irusri.com';
  }

  showInfo(): void {
    this.popupFacadeService.showInfoPopup(this.language)
  }
}
