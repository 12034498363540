<div class="main-container">
  <div class="list-container">
    <div cdkDropList #finalList="cdkDropList" id="finalList" [cdkDropListData]="resourcesList"
      [cdkDropListConnectedTo]="[doneList]" class="item-list" (cdkDropListDropped)="drop($event)">
      <div class="item-box" *ngFor="let item of resourcesList" cdkDrag [cdkDragData]="item"
        [cdkDragDisabled]="!isDragAndDropEnabled" (click)="openPopupAndMoveToTeam(item)">
        <span class="icon-container">
          <img [src]="item.image" [alt]="item.name">
        </span>
        <span class="item-text bold">
          {{item.name}}
        </span>
      </div>
    </div>
  </div>

  <div class="list-container">
    <div cdkDropList #doneList="cdkDropList" id="doneList" [cdkDropListData]="teamList"
      [cdkDropListConnectedTo]="[finalList]" class="item-list" [cdkDropListDisabled]="!isDragAndDropEnabled"
      (cdkDropListDropped)="drop($event)">
      <!-- Show the custom image and text only when teamList is empty -->
      <ng-container *ngIf="teamList.length === 0">
        <div class="empty">
          <div class="empty-list-image"></div>
          <div *ngIf="isDragAndDropEnabled" class="empty-list-text bold">{{language.dragAndDropResources}}</div>
          <div *ngIf="!isDragAndDropEnabled" class="empty-list-text bold">{{language.addResourceToCreatePodTeam}}</div>
        </div>
      </ng-container>

      <!-- Show the list items when teamList is not empty -->
      <ng-container *ngIf="teamList.length > 0">
        <div class="item-box justify-between" *ngFor="let item of teamList" cdkDrag
          (click)="openPopupForFinalListItem(item)">
          <div class="inner-content justify-between">
            <span class="icon-container">
              <img [src]="item.image" [alt]="item.name">
            </span>
            <span class="item-text bold">
              {{item.name}}
            </span>
          </div>
          <i (click)="removeResource(item); closeIconClick()" class="bi bi-x-lg close-icon"></i>
        </div>
      </ng-container>

    </div>
  </div>
</div>