<div class="header-container">
  <div class="logo">
    <a href="https://irusrigroup.com/">
      <img src="../../../assets/images/irusri-logo.svg" alt="Irusri Logo">
    </a>
  </div>
  <div class="right-side">
    <label class="switch">
      <input type="checkbox" (click)="toggleLanguage()" [checked]="!isSwSelected">
      <span class="slider round">
        <span class="toggle-text" [ngClass]="isSwSelected ? 'sw' : 'en'">
          {{ isSwSelected ? 'SW' : 'EN' }}
        </span>
      </span>
    </label>

    <div class="buttons-container">
      <button class="btn btn-primary drop-shadow" style="margin-right: 15px;" (click)="callPhoneNumber()">
        <i style="margin-right: 5px;" class="bi bi-telephone-fill"></i>
        +46 73 044 58 55
      </button>

      <button class="btn btn-primary drop-shadow" (click)="sendEmail()">
        <i style="margin-right: 5px;" class="bi bi-envelope-fill"></i>
        hej@irusri.com
      </button>
    </div>

    <button class="btn info-button btn-primary drop-shadow" (click)="showInfo()">
      <i class="bi bi-info-lg"></i>
    </button>

    <div class="dropdown-container" [ngClass]="{ 'show-dropdown': showDropdown }">
      <div class="mb-2" (click)="callPhoneNumber()">
        <i style="margin-right: 5px;" class="bi bi-telephone-fill"></i>
        +46 73 044 58 55
      </div>

      <div (click)="sendEmail()">
        <i style="margin-right: 5px;" class="bi bi-envelope-fill"></i>
        hej@irusri.com
      </div>
    </div>

    <button class="hamburger-icon" (click)="toggleDropdown()">
      <i [ngClass]="showDropdown ? 'bi bi-x' : 'bi bi-list'"></i>
    </button>
  </div>
</div>