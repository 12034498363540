import { Position } from "./position.model";

export class Language {
  id = '';
  stack_category_id = '';
  name = '';
  description = '';
  status = '';
  created_at: string | null = null;
  updated_at: string | null = null;
  position_categories: Array<Position> = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.stack_category_id = json.stack_category_id;
      this.name = json.name;
      this.description = json.description;
      this.status = json.status;
      this.created_at = json.created_at;
      this.updated_at = json.updated_at;
      this.position_categories = (json.position_categories || []).map((positionData: any) => new Position(positionData));
    }
  }
}
