import { Stack } from "./stack.model";

export class Category {
  id = '';
  name = '';
  description = '';
  status = '';
  created_at: string | null = null;
  updated_at: string | null = null;
  stack_categories: Array<Stack> = [];
  image = '';

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.name = json.name;
      this.description = json.description;
      this.status = json.status;
      this.created_at = json.created_at;
      this.updated_at = json.updated_at;
      this.stack_categories = (json.stack_categories || []).map((stackData: any) => new Stack(stackData));
      this.image = json.image;
    }
  }
}
