export class Position {
  id = '';
  language_category_id = '';
  name = '';
  description = '';
  status = '';
  created_at: string | null = null;
  updated_at: string | null = null;
  price = {
    currency: "",
    amount: 0
  }

  // custom properties
  is_selected = false;
  qty = 1;
  no_of_days = 1;
  qty_x_days = 1;
  amount = 0
  hours = 0;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.language_category_id = json.language_category_id;
      this.name = json.name;
      this.description = json.description;
      this.status = json.status;
      this.created_at = json.created_at;
      this.updated_at = json.updated_at;
      this.price = json.price;

      // custom properties
      json.is_selected ? this.is_selected = json.is_selected : false;
      json.qty ? this.qty = json.qty : 1;
      json.no_of_days ? this.no_of_days = json.no_of_days : 1;
      json.qty_x_days ? this.qty_x_days = json.qty_x_days : 1;
      json.amount ? this.amount = json.amount : 0;
      json.hours ? this.hours = json.hours : 0;
    }
  }
}
