<div class="modal-heading">
  <div class="modal-close-btn">
    <i type="button" aria-label="Close" class="bi bi-x-lg" (click)="onSubmit('CLOSE')"></i>
  </div>
  <div class="row">
    <div class="modal-title header-text">{{language.selectTheTypesOf+ ' ' + heading + ' ' + language.required}}</div>
  </div>
</div>

<div class="modal-body">
  <!-- <table class="table table-borderless">
    <thead>
      <tr>
        <th class="f-14 p-0 opacity-60 bold"></th>
        <th class="f-14 p-0 opacity-60 bold"></th>
        <th class="f-14 p-0 opacity-60 bold text-center">QTY</th>
        <th class="f-14 p-0 opacity-60 bold text-center">NO OF DAYS</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let stackCategory of dataSet">
        <tr *ngIf="stackCategory.name">
          <td colspan="4" class="f-18 p-0 pt-2 bold">
            {{ stackCategory.name }}
          </td>
        </tr>
        <ng-container *ngFor="let languageCategory of stackCategory.language_categories">
          <tr *ngIf="languageCategory.name">
            <td colspan="4" class="f-16 p-0 pt-1 opacity-70 bold">
              {{ languageCategory.name }}
            </td>
          </tr>
          <ng-container *ngFor="let positionCategory of languageCategory.position_categories">
            <tr class>
              <td style="padding-left: 12px;" class="tb-data">
                <input class="form-check-input" type="checkbox" id="" name="" [checked]="positionCategory.is_selected"
                  (click)="handleSelection(positionCategory, $event)">
              </td>
              <td style="padding-left: 12px;" class="tb-data f-16 opacity-60">{{ positionCategory.name }}</td>
              <td class="tb-data text-center">
                <i type="button" class="bi bi-dash" (click)="decrementQuantity(positionCategory)"></i>
                <span class="line">|</span>
                {{ positionCategory.qty }}
                <span class="line">|</span>
                <i type="button" class="bi bi-plus" (click)="incrementQuantity(positionCategory)"></i>
              </td>
              <td class="tb-data text-center">
                <i type="button" class="bi bi-dash" (click)="decrementDays(positionCategory)"></i>
                <span class="line">|</span>
                {{ positionCategory.no_of_days }}
                <span class="line">|</span>
                <i type="button" class="bi bi-plus" (click)="incrementDays(positionCategory)"></i>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table> -->

  <div [formGroup]="resourcesForm" class="resources-container">
    <div class="row resources-header">
      <div class="resources-section ms-1"></div>
      <div class="qty-section text-center">
        <span class="table-heading">{{language.qty}}</span>
      </div>
      <div class="days-section text-center">
        <span class="table-heading">{{language.noOfDays}}</span>
      </div>
      <div class="drop-section"></div>
    </div>

    <div class="row resources-body">
      <div class="accordion" id="resourcesAccordion">
        <ng-container *ngFor="let stackCategory of dataSet; let i = index">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <ng-container *ngIf="dataSet.length != 1; else headingBlock">
                <div class="accordion-button stack-name" [ngClass]="i != 0 ? 'collapsed' : ''" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#stackCategory' + i">
                  {{ stackCategory.name }}
                </div>
              </ng-container>

              <ng-template #headingBlock>
                <div class="stack-name py-2" *ngIf="stackCategory.name">
                  {{ stackCategory.name }}
                </div>
              </ng-template>
            </h2>

            <ng-container *ngFor="let languageCategory of stackCategory.language_categories">
              <div [id]="'stackCategory' + i" class="accordion-collapse collapse" [ngClass]="i == 0 ? 'show' : ''"
                data-bs-parent="#resourcesAccordion">
                <div class="accordion-body">
                  <span class="d-block language-name">{{ languageCategory.name }}</span>
                  <ng-container *ngFor="let positionCategory of languageCategory.position_categories">
                    <div class="row my-2">
                      <div class="resources-section">
                        <div class="form-check">
                          <input class="form-check-input checkbox" type="checkbox" id="" name=""
                            formArrayName="positionCategory" [checked]="positionCategory.is_selected"
                            (click)="handleSelection(positionCategory, $event)">

                          <label class="form-check-label d-flex flex-wrap align-items-center position-name" for="">
                            {{ positionCategory.name }}
                            <small class="span-primary px-2">&#x25CF;</small>
                            <span class="price-text">
                              {{ positionCategory.price.amount | currency:positionCategory.price.currency + ' ' }}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="qty-section d-flex justify-content-center align-items-center position-name">
                        <i type="button" class="bi bi-dash " (click)="decrementQuantity(positionCategory)"></i>
                        <span class="line px-1">|</span>
                        {{ positionCategory.qty }}
                        <span class="line px-1">|</span>
                        <i type="button" class="bi bi-plus" (click)="incrementQuantity(positionCategory)"></i>
                      </div>
                      <div class="days-section d-flex justify-content-center align-items-center position-name">
                        <i type="button" class="bi bi-dash" (click)="decrementDays(positionCategory)"></i>
                        <span class="line px-1">|</span>
                        {{ positionCategory.no_of_days }}
                        <span class="line px-1">|</span>
                        <i type="button" class="bi bi-plus" (click)="incrementDays(positionCategory)"></i>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal-foot">
  <button type="button" class="btn btn-primary rounded-2 f-16 w-100" (click)="onSubmit('CONFIRM')">
    {{language.saveResources}}
  </button>
</div>