import { Component, OnInit } from '@angular/core';
import { PopupFacadeService } from './popup-facade/popup-facade.service';
import { PodService } from './services/pod.service';
import { Category } from './models/category/category.model';
import { Stack } from './models/category/stack.model';
import { Summary } from './models/quotation/summary.model';
import { Language } from './models/category/language.model';
import { Position } from './models/category/position.model';
import { ActivatedRoute, Router } from '@angular/router';
import { languageEn, languageSw } from 'src/assets/language/language';
import { LoaderService } from './library/loader/loader.service';
import { mergeMap, of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'treinetic-prod-team';
  finalSummary: any = {};
  rawDataSet: Category[] = [];
  teamList: Category[] = [];
  rawDatacopy: Stack[] = [];
  quotationId: string = '';
  isSwSelected: boolean = true;
  selectedLanguage: string = 'sw';
  isDiscounted: boolean = false;
  no_of_hours: number = 8;

  language = this.isSwSelected ? languageSw : languageEn

  constructor(
    private popupFacadeService: PopupFacadeService,
    private service: PodService,
    private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderService
  ) {
    this.route.queryParams.subscribe(params => {
      this.selectedLanguage = params['lang'] ? params['lang'] : 'sw';
      this.isSwSelected = this.selectedLanguage === 'sw';
      this.fetchCategories(this.selectedLanguage);
      this.updateLanguage();
    });
  }

  ngOnInit(): void { }

  updateLanguage() {
    this.language = this.isSwSelected ? languageSw : languageEn
    this.teamList = [];
  }

  fetchCategories(lang: string) {
    this.loader.show("Fetching Resources...");

    this.service.getAllCategories(lang).subscribe({
      next: (data: Category[]) => {
        this.rawDataSet = data;
        this.rawDatacopy = this.rawDataSet[0].stack_categories;
        this.loader.hide();
      },
      error: (error: any) => {
        console.error(error);
        this.loader.hide();
      }
    });
  }

  onTeamListChange(updatedTeamList: Category[]) {
    this.teamList = updatedTeamList;
  }

  onLanguageChange(language: string) {
    this.selectedLanguage = language === 'sw' ? 'sw' : 'en';

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { lang: this.selectedLanguage },
      queryParamsHandling: 'merge'
    });
  }

  moveCategory(emptycategory: Category) {
    this.rawDataSet.push(emptycategory)
    const index = this.teamList.indexOf(emptycategory);
    if (index !== -1) {
      this.teamList.splice(index, 1);
    }
  }

  summary(summary: Summary) {
    this.finalSummary = summary;
  }

  discount(discountChecked: boolean): void {
    this.isDiscounted = discountChecked;
  }

  filterInactiveData(initData: Category[]): Category[] {
    const data: Category[] = JSON.parse(JSON.stringify(initData));
    const filteredCategories: Category[] = [];

    for (const category of data) {
      const filteredStacks: Stack[] = [];

      for (const stack of category.stack_categories) {
        const filteredLanguages: Language[] = [];

        for (const language of stack.language_categories) {
          const filteredPositions: Position[] = language.position_categories.filter(position => position.is_selected);

          if (filteredPositions.length > 0) {

            filteredPositions.forEach((position) => {
              position.hours = this.no_of_hours;
            });

            language.position_categories = filteredPositions;
            filteredLanguages.push(language);
          }
        }

        if (filteredLanguages.length > 0) {
          stack.language_categories = filteredLanguages;
          filteredStacks.push(stack);
        }
      }

      if (filteredStacks.length > 0) {
        category.stack_categories = filteredStacks;
        filteredCategories.push(category);
      }
    }

    return filteredCategories;
  }

  isSaveDisabled(): boolean {
    return this.teamList.length === 0;
  }

  isFinalizeDisabled(): boolean {
    return false
  }

  saveQuotation(event: any, isDownload = true) {
    isDownload && this.loader.show("Saving Quotation...");

    this.service.downloadQuotation(this.filterInactiveData(this.teamList), this.finalSummary, this.isDiscounted, this.selectedLanguage).subscribe(
      (response: any) => {
        isDownload && this.loader.hide();
        isDownload && this.downloadPDF(response.pdf_url);
        this.quotationId = response.id;
      },
      (error: any) => {
        console.error(error);
        isDownload && this.loader.hide();
      }
    );
  }

  downloadPDF(pdfUrl: any) {
    this.loader.show("Downloading Quotation...");
    const timestamp = Date();

    const updatedPDFUrl = `${pdfUrl}?timestamp=${timestamp}`;

    fetch(updatedPDFUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;

        const fileName = 'Your Pod Team Quotation.pdf';

        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(error => {
        console.error('Error downloading PDF:', error);
      })
      .finally(() => {
        this.loader.hide();
      });
  }

  finalzeTeam(event: any) {
    this.popupFacadeService.showContactFormPopup(this.language, this.isDiscounted, (data: any) => {
      if (data) {
        this.loader.show("Finalizing Team...");

        const saveQuotationObservable = this.filterInactiveData(this.teamList).length > 0
          ? this.service.downloadQuotation(this.filterInactiveData(this.teamList), this.finalSummary, this.isDiscounted, this.selectedLanguage)
          : of(null); // If no saving is needed, return an observable that completes immediately

        saveQuotationObservable.pipe(
          mergeMap((response: any) => {
            if (response) {
              data.id = response.id;
            } else {
              data.id = this.quotationId;
            }
            return this.service.finalizeTeam(data, this.selectedLanguage);
          })
        ).subscribe(
          (response: any) => {
            console.log(response);
            this.loader.hide();
          },
          (error: any) => {
            console.error(error);
            this.loader.hide();
          }
        );
      }
    });
  }

}
