import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Category } from 'src/app/models/category/category.model';
import { PopupFacadeService } from 'src/app/popup-facade/popup-facade.service';

@Component({
  selector: 'app-team-list-component',
  templateUrl: './team-list-component.component.html',
  styleUrls: ['./team-list-component.component.scss']
})
export class TeamListComponentComponent {
  @Input() resourcesList: Category[] = [];
  @Input() teamList: Category[] = [];
  @Input() language: any;
  @Output() teamListChange: EventEmitter<Category[]> = new EventEmitter<Category[]>();
  closeIconClicked: boolean = false;
  isDragAndDropEnabled: boolean = true;

  constructor(private popupFacadeService: PopupFacadeService) {
    this.checkDeviceType();
  }

  // Detect window resize events to check for mobile/desktop
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkDeviceType();
  }

  checkDeviceType() {
    // You can adjust the width threshold to match your definition of mobile/desktop
    const isMobile = window.innerWidth < 769;
    this.isDragAndDropEnabled = !isMobile;
  }

  drop(event: CdkDragDrop<Category[]>) {
    if (this.isDragAndDropEnabled) {

      if (event.previousContainer === event.container) {
        // Item moved within the same container
        if (event.previousContainer.id === 'doneList') { }
      } else {
        if (event.previousContainer.id !== 'doneList') {
          const movedItem = event.item.data;
          this.openResourcesPopup(movedItem, (success: boolean) => {
            if (success) {
              this.moveItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            }
          }, true);
        } else {
          this.moveItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
      }
    }
  }

  openPopupForFinalListItem(item: Category) {
    if (!this.closeIconClicked) {
      this.openResourcesPopup(item, (success: boolean) => {
        if (success) {
          // this.moveItem(this.resourcesList, this.teamList, this.resourcesList.indexOf(item), this.teamList.length);
        }
      });
    }
    this.closeIconClicked = false;
  }

  openPopupAndMoveToTeam(item: Category) {
    this.openResourcesPopup(item, (success: boolean) => {
      if (success) {
        this.moveItem(this.resourcesList, this.teamList, this.resourcesList.indexOf(item), this.teamList.length);
      }
    }, true);
  }

  moveItem(source: any[], target: any[], sourceIndex: number, targetIndex: number) {
    transferArrayItem(source, target, sourceIndex, targetIndex);
    this.teamListChange.emit(this.teamList);
  }

  removeResource(item: Category) {
    this.moveItem(this.teamList, this.resourcesList, this.teamList.indexOf(item), this.resourcesList.length);
  }

  closeIconClick() {
    this.closeIconClicked = true;
  }

  //open modal
  openResourcesPopup(resource: Category, callback: (success: boolean) => void, isResource?: boolean) {
    const tempResource = new Category(resource);
    const newResource = isResource ? this.setFieldsInPositionCategories(tempResource) : tempResource;
    const isCheckable = isResource ? true : false;
    this.popupFacadeService.showResourceAllocationPopup(newResource.name, newResource.stack_categories, this.language, isCheckable, (data: any) => {
      if (data) {
        resource.stack_categories = data;
        this.checkAndRemoveEmptyCategories(resource);
        callback(true);
      } else {
        callback(false);
      }
    });
  }

  setFieldsInPositionCategories(category: Category): Category {
    for (const stackCategory of category.stack_categories) {
      for (const languageCategory of stackCategory.language_categories) {
        for (const positionCategory of languageCategory.position_categories) {
          positionCategory.is_selected = false;
          positionCategory.qty = 1;
          positionCategory.no_of_days = 1;
          positionCategory.qty_x_days = 1;
          positionCategory.amount = 0;
        }
      }
    }

    return category; // Return the modified category
  }


  checkAndRemoveEmptyCategories(mainCategory: any) {
    let isAnyPositionActive = false;

    for (const stackCategory of mainCategory.stack_categories) {
      for (const languageCategory of stackCategory.language_categories) {
        for (const positionCategory of languageCategory.position_categories) {
          if (positionCategory.is_selected) {
            isAnyPositionActive = true;
            break;
          }
        }
        if (isAnyPositionActive) {
          break;
        }
      }
      if (isAnyPositionActive) {
        break;
      }
    }

    if (!isAnyPositionActive) {
      this.moveItem(this.teamList, this.resourcesList, this.teamList.indexOf(mainCategory), this.resourcesList.length);
    }
  }

}
