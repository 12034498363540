import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PopupModalEventAtion } from '../popup-facade.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-no-of-days-popup',
  templateUrl: './no-of-days-popup.component.html',
  styleUrls: ['./no-of-days-popup.component.scss']
})
export class NoOfDaysPopupComponent implements OnInit {

  daysForm!: FormGroup;
  isError = false;
  errorMsg = '';
  incomingDaysCount?= 14;
  language: any;
  daysOptions: any[] = [];



  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.daysOptions = [
      { id: 'twoWeeks', label: `2 ${this.language.weeks}`, value: 14 },
      { id: 'fourWeeks', label: `4 ${this.language.weeks}`, value: 28 },
      { id: 'sixWeeks', label: `6 ${this.language.weeks}`, value: 42 },
      { id: 'twoMonths', label: `2 ${this.language.months}`, value: 56 },
      { id: 'customDays', label: `${this.language.customValue}`, value: 'custom' },
    ];

    this.daysForm = this.formBuilder.group({
      numberOfDays: [null, Validators.required],
      customValue: [null, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(1)]]
    });

    const matchingOption = this.incomingDaysCount ? this.daysOptions.find(option => option.value === this.incomingDaysCount) : null

    if (matchingOption) {
      this.daysForm.patchValue({
        numberOfDays: matchingOption.value
      });
    } else {
      this.daysForm.patchValue({
        numberOfDays: 'custom',
        customValue: this.incomingDaysCount
      });
    }

    this.daysForm.get('numberOfDays')?.valueChanges.subscribe(value => {
      if (value === 'custom') {
        this.daysForm.get('customValue')?.setValidators([Validators.required, Validators.pattern(/^\d+$/), Validators.min(1)]);
      } else {
        this.daysForm.get('customValue')?.clearValidators();
      }
      this.daysForm.get('customValue')?.updateValueAndValidity();
    });
  }


  onSubmit(eventAction: PopupModalEventAtion): void {
    console.log(this.daysForm);
    if (eventAction === 'CLOSE') {
      this.errorMsg = '';
      this.isError = false;
      this.activeModal.close({
        popupModalEventAtion: eventAction,
      });
    } else {
      // Check if the form is invalid
      if (this.daysForm.invalid) {
        // Display error messages based on form control status
        const numberOfDaysControl = this.daysForm.get('numberOfDays') as FormControl;
        const customValueControl = this.daysForm.get('customValue') as FormControl;

        if (numberOfDaysControl.hasError('required')) {
          this.isError = true;
          this.errorMsg = this.language.pleaseSelectAValue;
        }
        if (customValueControl.hasError('required')) {
          this.isError = true;
          this.errorMsg = this.language.pleaseEnterACustomValue;
        }
        if (customValueControl.hasError('pattern') || customValueControl.hasError('min')) {
          this.isError = true;
          this.errorMsg = this.language.pleaseEnterAValidNumber;
        }
        return;
      }

      const selectedValue = this.daysForm.value.numberOfDays === 'custom'
        ? this.daysForm.value.customValue
        : this.daysForm.value.numberOfDays;

      this.activeModal.close({
        popupModalEventAtion: eventAction,
        data: selectedValue

      });
      this.errorMsg = '';
      this.isError = false;
    }
  }


}
