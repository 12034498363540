import { Injectable } from '@angular/core';
import { getServiceUrl } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Category } from '../models/category/category.model';
import { Summary } from '../models/quotation/summary.model';
import { Details } from '../models/quotation/details.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PodService {
  private apiUrl: string;

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.apiUrl = getServiceUrl();
  }

  getAllCategories(language: string): Observable<Category[]> {
    const headers = new HttpHeaders({
      'X-localization': language || 'sw'
    });
    return this.http.get<Category[]>(`${this.apiUrl}/categories`, { headers: headers }).pipe(map((res) => {
      let arr: Array<Category> = []
      res.forEach((item: any) => {
        arr.push(new Category(item))
      });
      return arr;
    }), catchError((error) => {
      return throwError(error);
    }));
  }

  downloadQuotation(team: Category[], summary: Summary, isStartUps: boolean, language: string): Observable<any> {
    const summaryData: Summary = {
      "total_amount": summary.total_amount,
      "total_days": summary.total_days
    }
    const headers = new HttpHeaders({
      'X-localization': language || 'sw'
    });

    const details = (team || []).map((detailsData: any) => new Details(detailsData));

    const data = {
      "data": {
        "details": details,
        "summary": summaryData
      },
      "start_ups": isStartUps
    }
    return this.http.post<any>(`${this.apiUrl}/quotation-details`, data, { headers: headers })
  }

  finalizeTeam(details: any, language: string): Observable<any> {
    const headers = new HttpHeaders({
      'X-localization': language || 'sw'
    });

    const data = {
      "id": details.id,
      "name": details.name,
      "email": details.emailAddress,
      "contact_number": details.contactNumber,
      "description": details.projectDesc,
      "business_name": details.businessName
    }
    return this.http.post<any>(`${this.apiUrl}/quotation`, data, { headers: headers })
  }

}
