import { Position } from "./position.model";

export class Language {
  name = '';
  position_categories: Array<Position> = [];

  constructor(json?: any) {
    if (json) {
      this.name = json.name;
      this.position_categories = (json.position_categories || []).map((positionData: any) => new Position(positionData));
    }
  }

  getSendableJson(): any {
    let positions: Array<Position> = [];
    for (let position of this.position_categories) {
      positions.push(position.getSendableJson())
    }
    return {
      "name": this.name,
      "position_category": positions
    }
  }
}
