
export const environment = {
  production: false,
};


export function getServiceUrl() {
  console.log('get service url dev');
  return 'https://dev-irusri-pod-server.treinetic.xyz/api/v1.0';
}
