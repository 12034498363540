import { Stack } from "./stack.model";

export class Details {

  top_category = '';
  stack_categories: Array<Stack> = [];

  constructor(json?: any) {
    if (json) {
      this.top_category = json.name;
      this.stack_categories = (json.stack_categories || []).map((stackData: any) => new Stack(stackData));
    }
  }

  getSendableJson(): any {
    let stacks: Array<Stack> = [];
    for (let stack of this.stack_categories) {
      stacks.push(stack.getSendableJson())
    }
    return {
      "top_category": this.top_category,
      "stack_categories": stacks
    }
  }
}
