<div class="modal-heading">
  <div class="modal-close-btn">
    <i type="button" aria-label="Close" class="bi bi-x-lg" (click)="onSubmit('CLOSE')"></i>
  </div>
  <div class="row">
    <!-- <div class="modal-title f-20">Select the number of days required</div> -->
    <div class="modal-title f-20">{{ language.selectTheNumberOfDaysRequired }}</div>
  </div>
</div>

<div class="modal-body">
  <form ngbAutofocus [formGroup]="daysForm" (ngSubmit)="onSubmit('CONFIRM')">
    <div class="form-check mb-2" *ngFor="let option of daysOptions">
      <input class="form-check-input" type="radio" formControlName="numberOfDays" [value]="option.value">
      <label class="form-check-label f-16">{{ option.label }}</label>
    </div>
    <div class="col-10 form-group mb-2 mx-2" *ngIf="daysForm.value.numberOfDays === 'custom'">
      <input type="text" class="form-control mx-4" placeholder="{{ language.enterCustomValue }}"
        formControlName="customValue">
    </div>
    <div class="text-danger mx-4 px-2" *ngIf="daysForm.invalid">{{errorMsg}}</div>
  </form>
</div>

<div class="modal-foot">
  <button type="button" class="btn btn-primary f-16 rounded-2 w-100" (click)="onSubmit('CONFIRM')">
    {{ language.saveDetails }}
  </button>
</div>