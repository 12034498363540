import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupFacadeService, PopupModalEventAtion } from '../popup-facade.service';
import { CurrencyPipe } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-resource-allocaion',
  templateUrl: './resource-allocaion.component.html',
  styleUrls: ['./resource-allocaion.component.scss'],
  providers: [CurrencyPipe]
})
export class ResourceAllocaionComponent implements OnInit {
  dataSet: any[] = [];
  language: any;
  heading = ''
  resourcesForm!: FormGroup;
  isCheckable: boolean = false;
  errorMsg = '';

  constructor(
    private activeModal: NgbActiveModal,
    private popupFacadeService: PopupFacadeService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.resourcesForm = this.formBuilder.group({
      positionCategory: new FormArray([], Validators.required)
    });
  }

  onSubmit(eventAction: PopupModalEventAtion): void {
    if (eventAction === 'CLOSE') {
      this.errorMsg = '';
      this.activeModal.close({
        popupModalEventAtion: eventAction,
      });
    } else {
      // Check if the form is invalid
      if (this.resourcesForm.invalid && this.isCheckable) {
        this.errorMsg = 'Error Message Here...';
        return;
      }

      this.activeModal.close({
        popupModalEventAtion: eventAction,
        data: this.dataSet
      });

      this.errorMsg = '';
    }
  }

  handleSelection(positionCategory: any, event: Event) {
    const checkbox = event.target as HTMLInputElement;
    positionCategory.is_selected = checkbox.checked;

    const positionCategoryFormArray = this.resourcesForm.controls['positionCategory'] as FormArray

    if (checkbox.checked) {
      positionCategoryFormArray.push(new FormControl(positionCategory.id));
    } else {
      let index = positionCategoryFormArray.controls.findIndex(i => i.value == positionCategory.id);
      positionCategoryFormArray.removeAt(index);
    }

  }

  decrementQuantity(positionCategory: any) {
    if (positionCategory.qty > 1) {
      positionCategory.qty--;
    }
  }

  decrementDays(positionCategory: any) {
    this.popupFacadeService.showDaysSelectionPopup(this.language, (data: any) => {
      if (data) {
        positionCategory.no_of_days = data
      }
    }, positionCategory.no_of_days)
  }

  incrementQuantity(positionCategory: any) {
    positionCategory.qty++;
  }

  incrementDays(positionCategory: any) {
    this.popupFacadeService.showDaysSelectionPopup(this.language, (data: any) => {
      if (data) {
        positionCategory.no_of_days = data
      }
    }, positionCategory.no_of_days)
  }

  calculateTotal(positionCategory: any) {
    return positionCategory.qty * positionCategory.no_of_days * positionCategory.hourly_rate;
  }

  calculateCostAndDate(): { totalCost: number, maximumDate: number } {
    let totalCost = 0;
    let maximumDate = 0;

    for (const mainCategory of this.dataSet) {
      for (const stackCategory of mainCategory.stack_categories) {
        for (const languageCategory of stackCategory.language_categories) {
          for (const positionCategory of languageCategory.position_categories) {
            totalCost += this.calculateTotal(positionCategory);
            maximumDate = Math.max(maximumDate, positionCategory.no_of_days);
          }
        }
      }
    }

    return { totalCost, maximumDate };
  }

}
