<div class="main-container">
  <div class="table-container">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th class="table-headings table-headings">{{language.resources}}</th>
          <th class="table-headings text-center">{{language.qty}}</th>
          <th class="table-headings text-center">{{language.noOfDays}}</th>
          <th class="table-headings text-center">{{language.total}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let mainCategory of dataSet">
          <tr *ngIf="isCategorySelected(mainCategory)">
            <td colspan="4" class="category-text p-0 pt-2 primary-text bold">{{ mainCategory.name }}</td>
          </tr>
          <ng-container *ngFor="let stackCategory of mainCategory.stack_categories">
            <tr *ngIf="stackCategory.name && isStackCategorySelected(stackCategory)">
              <td colspan="4" class="stack-text p-0 pt-2 bold">
                {{ stackCategory.name }}
              </td>
            </tr>
            <ng-container *ngFor="let languageCategory of stackCategory.language_categories">
              <tr *ngIf="languageCategory.name && isLanguageCategorySelected(languageCategory)">
                <td colspan="4" class="language-text p-0 pt-1 opacity-70 bold">
                  {{ languageCategory.name }}
                </td>
              </tr>
              <ng-container *ngFor="let positionCategory of languageCategory.position_categories">
                <tr *ngIf="positionCategory.is_selected">
                  <td style="padding-left: 12px;" class="tb-data language-text opacity-60">{{ positionCategory.name }}
                  </td>
                  <td class="tb-data language-text text-center">
                    <i type="button" class="bi bi-dash"
                      (click)="decrementQuantity(mainCategory,positionCategory, languageCategory.position_categories)"></i>
                    <span class="line">|</span>
                    {{ positionCategory.qty }}
                    <span class="line">|</span>
                    <i type="button" class="bi bi-plus" (click)="incrementQuantity(positionCategory)"></i>
                  </td>
                  <td class="tb-data language-text text-center">
                    <i type="button" class="bi bi-dash"
                      (click)="decrementDays(mainCategory,positionCategory, languageCategory.position_categories)"></i>
                    <span class="line">|</span>
                    {{ positionCategory.no_of_days }}
                    <span class="line">|</span>
                    <i type="button" class="bi bi-plus" (click)="incrementDays(positionCategory)"></i>
                  </td>
                  <td class="tb-data text-right">
                    <span class="total-desc opacity-50">
                      {{ (positionCategory.price.amount | currency:positionCategory.price.currency + ' ') +' * '+
                      no_of_hours + 'H' + ' * ' + positionCategory.no_of_days +' '+
                      (positionCategory.no_of_days > 1 ? language.days : language.day) +' * '+ positionCategory.qty }}
                    </span>
                    <span class="total-desc opacity-50 equal-mark">=</span>
                    <span class="total language-text">
                      {{ calculateTotal(positionCategory) | currency:positionCategory.price.currency + ' ' }}
                    </span>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
          <tr class="border-bottom spacer-row">
            <td colspan="4"></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="total-container">
    <div class="d-flex discount-text justify-content-between align-items-center px-3">
      <div>{{ language.startupsOrSoleProprietorships }}</div>
      <div>
        <label class="switch">
          <input type="checkbox" (click)="toggleDiscount()" [checked]="!isChecked">
          <span class="slider" [attr.data-before-content]="isChecked ? language.yes : language.no"></span>
        </label>
      </div>
    </div>
    <div class="d-flex discount-text justify-content-between align-items-center px-3">
      <div>{{ language.discount }}</div>
      <div>{{ discount + '%' }}</div>
    </div>

    <div class="line"></div>

    <div class="d-flex justify-content-between category-text bold px-3">
      <div>{{language.totalCostForThePodTeam}}</div>
      <div> {{ calculateCostAndDate().totalCost | currency:language.currencyType + ' ' }}</div>
    </div>
    <div class="d-flex language-text justify-content-between px-3">
      <div>{{language.overallWorkingDays}}</div>
      <div>
        {{ calculateCostAndDate().maximumDate + ' ' +
        (calculateCostAndDate().maximumDate > 1 ? language.days : language.day) }}
      </div>
    </div>
  </div>
</div>