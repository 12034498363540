export const languageEn = {
  homeHeading: " Create your own pod team",
  homeDescription: "Using this tool, you can easily drag and drop developers into the central area to assemble your own team, all with just a few clicks.",
  selectTheTypesOf: "Select the types of",
  required: "Required",
  saveResources: "Save Resources",
  qty: "QTY",
  noOfDays: "NO OF DAYS",
  dragAndDropResources: "Drag and drop resources here to create your own Pod Team",
  addResourceToCreatePodTeam: "Add resources here to create your own Pod Team",
  resources: "RESOURCES",
  total: "TOTAL AMOUNT",
  totalCostForThePodTeam: "Total Cost for the Pod Team:",
  overallWorkingDays: "Overall no of working days:",
  days: "days",
  day: 'day',
  saveYourQuotation: "Save your quotation",
  downloadPDF: "Download PDF",
  finalizedYourPodTeam: "Finalized your Pod Team?",
  talkWithTheTeam: "Talk with the Team",
  enterYourDetails: "Enter your details",
  yourName: "Your Name",
  name: 'Name',
  emailAddress: 'Email Address',
  isRequired: " is a required field.",
  pleaseEnterValid: "Please enter a valid ",
  contactNumber: "Contact Number",
  businessName: "Business Name",
  projectDescription: "Project Description",
  descriptionAboutProject: "Description about the project",
  projectQuotation: "Project Quotation",
  saveDetails: "Save Details",
  copyrightNotice: "Copyright © Irusri Group 2023. All Rights Reserved",
  currencyType: 'USD',
  selectTheNumberOfDaysRequired: 'Select the number of days required',
  weeks: 'Weeks',
  months: 'months',
  customValue: 'Custom value',
  enterCustomValue: 'Enter custom value',
  pleaseEnterACustomValue: 'Please enter a custom value',
  pleaseSelectAValue: 'Please select a value',
  pleaseEnterAValidNumber: 'Please enter a valid number',
  nameMustContainOnlyAlphabets: 'Name must contain only alphabets',
  mustBe: 'must be',
  minimum: 'minimum',
  and: 'and',
  maximum: 'maximum',
  characters: 'characters',
  sendQuotation: 'Send Quotation',
  startupsOrSoleProprietorships: 'Startups or sole proprietorships',
  discount: 'Discount',
  yes: 'Yes',
  no: 'No',
  hours: 'hours',
  infoPopup: {
    title: 'To provide you with an understanding, here are a few examples showcasing the hours necessary for different projects.',
    okBtn: 'Ok',
    subContent1: {
      website: "Website:",
      basicWebsite: "Basic Static Website: 40 to 100+ hours",
      dynamicWebsite: "Dynamic Website (CMS-based): 80 to 200+ hours",
      eCommerceWebsite: "E-Commerce Website: 150 to 300+ hours",
      customWebApplication: "Custom Web Application: 200 to 500+ hours"
    },
    subContent2: {
      mobileApp: "Mobile App:",
      simpleMobileApp: "Simple Mobile App: 100 to 300+ hours (per platform, e.g., iOS and Android)",
      mediumComplexityMobileApp: "Medium Complexity Mobile App: 300 to 800+ hours (per platform)",
      complexMobileApp: "Complex Mobile App: 800 to 1500+ hours (per platform)",
    },
    subContent3: {
      cRMSystem: "CRM System:",
      basicCRMSystem: "Basic Customer relationship management (CRM) system: 200 to 500+ hours",
      mediumComplexityCRMSystem: "Medium Complexity CRM: 500 to 1000+ hours",
      customizedCRMSystem: "Customized CRM: 1000 to 2000+ hours",
    },
    subContent4: {
      eRPSystem: "Enterprise resource planning (ERP) Systems:",
      smallBusinessERPSystem: "Small Business ERP System: 500 to 1000+ hours",
      mediumComplexityERPSystem: "Medium Complexity ERP System: 1000 to 2000+ hours",
      largeEnterpriseERPSystem: "Large Enterprise ERP System: 2000 to 5000+ hours	",
    },
    pleaseNoteThatText: 'Please note that these estimates are general ranges and can vary widely based on specific project requirements. The following factors can influence the estimates:',
    featuresAndFunctionality: "Features and Functionality:",
    complexityAndNumberOfFeatures: "The complexity and number of features significantly impact development time. More features require more time.",
    designAndUserExperience: "Design and User Experience:",
    designingAUserFriendlyInterface: "Designing a user-friendly interface takes time. Complex interactions and custom UI elements can add hours to the project.",
    integration: "Integration:",
    ifTheProjectNeedsIntegrate: "If the project needs to integrate with other systems or APIs, additional development time may be required.",
    testingAndQualityAssurance: "Testing and Quality Assurance:",
    properTestingAndQualityAssurance: "Proper testing and quality assurance take time and are crucial for a successful project.",
    platformAndTechnology: "Platform and Technology:",
    chosenPlatformFrameworksLibrariesTechnologies: "The chosen platform (web, mobile), frameworks, libraries, and technologies can impact development time.",
    teamExperience: "Team Experience:",
    anExperiencedDevelopmentTeam: "An experienced development team might be more efficient, while a less experienced team might take longer.",
    projectManagement: "Project Management:",
    effectiveProjectManagement: "Effective project management helps streamline development. Poor project management can lead to delays.",
    customization: "Customization:",
    projectsRequiringHighLevelsOfCustomization: "Projects requiring high levels of customization often take longer to implement."
  }
}

export const languageSw = {
  homeHeading: "Skapa ditt egna team av utvecklare",
  homeDescription: "Med det här verktyget kan du enkelt dra och släppa utvecklare till mitten på webbsidan för att bygga ditt egna team, allt med bara några klick.",
  selectTheTypesOf: "Välj de typer av",
  required: "som krävs",
  saveResources: "Spara resurser",
  qty: "ANTAL",
  noOfDays: "ANTAL DAGAR",
  dragAndDropResources: "Dra och släpp resurser här för att skapa ditt eget pod-team",
  addResourceToCreatePodTeam: "Lägg till resurser här för att skapa ditt eget pod-team",
  resources: "RESURSER",
  total: "TOTALSUMMA",
  totalCostForThePodTeam: "Total kostnad för pod-teamet:",
  overallWorkingDays: "Totalt antal arbetsdagar:",
  days: "dagar",
  day: "dag",
  saveYourQuotation: "Spara din erbjudande",
  downloadPDF: "Ladda ner PDF",
  finalizedYourPodTeam: "Slutförde ditt pod-team?",
  talkWithTheTeam: "Låt oss prata med teamet",
  enterYourDetails: "Ange dina uppgifter",
  yourName: "Ditt namn",
  name: "Namn",
  emailAddress: "e-postadress",
  isRequired: " är ett obligatoriskt fält.",
  pleaseEnterValid: "Ange en giltig",
  contactNumber: "Kontaktnummer",
  businessName: "Företagsnamn",
  projectDescription: "Projekt beskrivning",
  descriptionAboutProject: "Beskrivning om projektet",
  projectQuotation: "Projektoffert",
  saveDetails: "Spara detaljer",
  copyrightNotice: "upphovsrätt © Irusri Group 2023. Alla rättigheter förbehållna",
  currencyType: 'SEK',
  selectTheNumberOfDaysRequired: 'Välj det antal dagar som krävs',
  weeks: 'Veckor',
  months: 'månader',
  customValue: 'Anpassat värde',
  enterCustomValue: 'Ange anpassat värde',
  pleaseEnterACustomValue: 'Ange ett anpassat värde',
  pleaseSelectAValue: 'Välj ett värde',
  pleaseEnterAValidNumber: 'var vänlig skriv in ett giltigt nummer',
  nameMustContainOnlyAlphabets: 'Namn kan bara innehålla bokstäver',
  mustBe: 'måste vara',
  minimum: 'minimum',
  and: 'och',
  maximum: 'maximal',
  characters: 'tecken',
  sendQuotation: 'skicka en offert',
  startupsOrSoleProprietorships: 'Startups eller enskild firma',
  discount: 'Rabatt',
  yes: 'Ja',
  no: 'Nej',
  hours: 'timmar',
  infoPopup: {
    title: 'För att ge dig en förståelse, här är några exempel som visar de timmar som krävs för olika projekt.',
    okBtn: 'Ok',
    subContent1: {
      website: "Hemsida:",
      basicWebsite: "Grundläggande statisk webbplats: 40 till 100+ timmar",
      dynamicWebsite: "Dynamisk webbplats (CMS-baserad): 80 till 200+ timmar",
      eCommerceWebsite: "E-handelswebbplats: 150 till 300+ timmar",
      customWebApplication: "Anpassad webbapplikation: 200 till 500+ timmar"
    },
    subContent2: {
      mobileApp: "Mobil app:",
      simpleMobileApp: "Enkel mobilapp: 100 till 300+ timmar (per plattform, t.ex. iOS och Android)",
      mediumComplexityMobileApp: "Mobilapp med medium komplexitet: 300 till 800+ timmar (per plattform)",
      complexMobileApp: "Komplex mobilapp: 800 till 1500+ timmar (per plattform)",
    },
    subContent3: {
      cRMSystem: "CRM-system:",
      basicCRMSystem: "Grundläggande Customer Relationship Management (CRM) system: 200 till 500+ timmar",
      mediumComplexityCRMSystem: "Medium komplexitet CRM: 500 till 1000+ timmar",
      customizedCRMSystem: "Medium komplexitet CRM: 500 till 1000+ timmar",
    },
    subContent4: {
      eRPSystem: "Enterprise Resource Planning (ERP) System :",
      smallBusinessERPSystem: "Small Business ERP-system: 500 till 1000+ timmar",
      mediumComplexityERPSystem: "Medium komplexitet ERP: 1000 till 2000+ timmar",
      largeEnterpriseERPSystem: "ERP för stora företag: 2000 till 5000+ timmar",
    },
    pleaseNoteThatText: 'Observera att dessa uppskattningar är generella intervall och kan variera kraftigt baserat på specifika projektkrav. Följande faktorer kan påverka uppskattningarna:',
    featuresAndFunctionality: "Funktioner och funktionalitet :",
    complexityAndNumberOfFeatures: "Komplexiteten och antalet funktioner påverkar utvecklingstiden avsevärt. Fler funktioner kräver mer tid.",
    designAndUserExperience: "Design och användarupplevelse :",
    designingAUserFriendlyInterface: "Att designa ett användarvänligt gränssnitt tar tid. Komplexa interaktioner och anpassade UI-element kan lägga till timmar till projektet.",
    integration: "Integration :",
    ifTheProjectNeedsIntegrate: "Om projektet behöver integreras med andra system eller API:er kan ytterligare utvecklingstid krävas.",
    testingAndQualityAssurance: "Testning och kvalitetssäkring :",
    properTestingAndQualityAssurance: "Korrekt testning och kvalitetssäkring tar tid och är avgörande för ett framgångsrikt projekt.",
    platformAndTechnology: "Plattform och teknik :",
    chosenPlatformFrameworksLibrariesTechnologies: "Den valda plattformen (webb, mobil), ramverk, bibliotek och teknologier kan påverka utvecklingstiden.",
    teamExperience: "Teamerfarenhet :",
    anExperiencedDevelopmentTeam: "Ett erfaret utvecklingsteam kan vara mer effektivt, medan ett mindre erfaret team kan ta längre tid.",
    projectManagement: "Projektledning :",
    effectiveProjectManagement: "Effektiv projektledning hjälper till att effektivisera utvecklingen. Dålig projektledning kan leda till förseningar.",
    customization: "Anpassning :",
    projectsRequiringHighLevelsOfCustomization: "Projekt som kräver höga nivåer av anpassning tar ofta längre tid att genomföra."
  }
}
