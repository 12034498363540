import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PopupModalOutput } from './popup-facade.service';

export abstract class PopupFacadeServiceHelper {
  private modalService: NgbModal;

  constructor(protected ms: NgbModal) {
    this.modalService = ms;
  }

  dissmissAll(): void {
    this.modalService.dismissAll();
  }

  protected createModalInstance(content: any, ngbModalOptions: NgbModalOptions, title?: string, message?: string): NgbModalRef {
    const modalRef = this.modalService.open(content, ngbModalOptions);

    if (title) {
      modalRef.componentInstance.title = title;
    }

    if (message) {
      modalRef.componentInstance.message = message;
    }

    return modalRef;
  }

  protected getOptions(param?: { size?: Size, bringToFront?: boolean }): NgbModalOptions {
    let ngbModalOptions: NgbModalOptions = {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false
    };

    if (param?.size) {
      ngbModalOptions.size = param?.size;
    }

    if (param?.bringToFront) {
      ngbModalOptions.windowClass = "z-index-bring-to-front" // Element will be brought to the front of the rendering stack.
    }

    return ngbModalOptions;
  }

  protected isConfirm(popupModalOutput: PopupModalOutput): boolean {
    return popupModalOutput.popupModalEventAtion === "CONFIRM";
  }

  protected isEventActionNo(popupModalOutput: PopupModalOutput): boolean {
    return popupModalOutput.popupModalEventAtion === "REJECT";
  }

  protected isEventActionClose(popupModalOutput: PopupModalOutput): boolean {
    return popupModalOutput.popupModalEventAtion === "CLOSE";
  }
}

type Size = "lg"
