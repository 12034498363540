import { Language } from "./language.model";

export class Stack {
  name = '';
  language_categories: Array<Language> = [];

  constructor(json?: any) {
    if (json) {
      this.name = json.name;
      this.language_categories = (json.language_categories || []).map((languageData: any) => new Language(languageData));
    }
  }

  getSendableJson(): any {
    let languages: Array<Language> = [];
    for (let language of this.language_categories) {
      languages.push(language.getSendableJson())
    }
    return {
      "name": this.name,
      "language_category": languages
    }
  }
}
