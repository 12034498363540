<div class="spinner-backdrop" *ngIf="showLoader">
  <table id="wrapper">
    <tr>
      <td>
        <div class="row justify-content-center">
          <div class="col-md-3">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="loader">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>
              <div class="col-md-12 p-4 text-styling" *ngIf="message">
                {{ message }}
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>