import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupFacadeServiceHelper } from './PopupFacadeHelper';
import { ResourceAllocaionComponent } from './resource-allocaion/resource-allocaion.component';
import { NoOfDaysPopupComponent } from './no-of-days-popup/no-of-days-popup.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { InfoPopupComponent } from './info-popup/info-popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupFacadeService extends PopupFacadeServiceHelper {

  constructor(modalService: NgbModal) {
    super(modalService);
  }

  showResourceAllocationPopup(heading: string, dataSet: any, language: any, isCheckable: boolean, yes: (data: any) => void): void {
    const modalRef = this.createModalInstance(
      ResourceAllocaionComponent,
      this.getOptions({ size: 'lg' }),
    );

    modalRef.componentInstance.dataSet = dataSet;
    modalRef.componentInstance.language = language;
    modalRef.componentInstance.heading = heading;
    modalRef.componentInstance.isCheckable = isCheckable;

    modalRef.result.then((popupModalOutput: PopupModalOutput) => {
      if (this.isConfirm(popupModalOutput)) {
        yes(popupModalOutput.data);
      }
    });
  }

  showDaysSelectionPopup(language: any, yes: (data: any) => void, daysCount?: number): void {
    const modalRef = this.createModalInstance(
      NoOfDaysPopupComponent,
      this.getOptions()
    );

    modalRef.componentInstance.language = language;

    if (daysCount) {
      modalRef.componentInstance.incomingDaysCount = daysCount;
    }

    modalRef.result.then((popupModalOutput: PopupModalOutput) => {
      if (this.isConfirm(popupModalOutput)) {
        yes(popupModalOutput.data);
      }
    })
  }

  showContactFormPopup(language: any, isDiscounted: boolean, yes: (data: any) => void): void {
    const modalRef = this.createModalInstance(
      ContactFormComponent,
      this.getOptions()
    )

    modalRef.componentInstance.language = language;
    modalRef.componentInstance.isDiscounted = isDiscounted;

    modalRef.result.then((popupModalOutput: PopupModalOutput) => {
      if (this.isConfirm(popupModalOutput)) {
        yes(popupModalOutput.data);
      }
    })
  }

  showInfoPopup(content: any): void {
    const modalRef = this.createModalInstance(
      InfoPopupComponent,
      this.getOptions({ size: 'lg' }),
    )

    modalRef.componentInstance.content = content;
  }

}

export type PopupModalEventAtion = "CONFIRM" | "REJECT" | "CLOSE";

export interface PopupModalOutput {
  popupModalEventAtion: PopupModalEventAtion;
  data: any;
}
