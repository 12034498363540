import { Component } from '@angular/core';
import { PopupModalEventAtion } from '../popup-facade.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent {

  content: any;

  constructor(private activeModal: NgbActiveModal) { }

  onSubmit(eventAction: PopupModalEventAtion): void {
    this.activeModal.close({
      popupModalEventAtion: eventAction,
    });

  }

}
