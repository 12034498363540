<div class="modal-heading">
  <div class="modal-close-btn">
    <i type="button" aria-label="Close" class="bi bi-x-lg" (click)="onSubmit('CLOSE')"></i>
  </div>
  <div class="row">
    <div class="modal-title">{{language.enterYourDetails}}</div>
  </div>
</div>

<div class="modal-body">
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit('CONFIRM')">
    <div class="mb-3">
      <label for="name" class="form-label col-form-label-sm mb-1 fw-semibold">{{language.yourName}}*</label>
      <input trim type="text" class="form-control form-control-sm bg-light" id="name" formControlName="name"
        [ngClass]="contactForm.get('name')?.touched && contactForm.get('name')?.invalid ? 'is-invalid': ''">
      <div class="text-danger">
        <small *ngIf="contactForm.get('name')?.touched && contactForm.get('name')?.hasError('required')">
          {{language.name + " " +language.isRequired}}
        </small>
        <small *ngIf="contactForm.get('name')?.touched && (contactForm.get('name')?.hasError('minlength') || 
          contactForm.get('name')?.hasError('maxlength'))">
          {{language.name + ' ' + language.mustBe + ' ' + language.minimum + ' 5 ' + language.and + ' ' +
          language.maximum + ' 50 ' + language.characters }}<br>
        </small>
        <small *ngIf="contactForm.get('name')?.touched && contactForm.get('name')?.hasError('pattern')">
          {{ language.nameMustContainOnlyAlphabets }}
        </small>
      </div>
    </div>

    <div class="mb-3">
      <label for="contactNum" class="form-label col-form-label-sm mb-1 fw-semibold">{{language.contactNumber}}*</label>
      <input type="text" class="form-control form-control-sm bg-light" id="contactNum" formControlName="contactNumber"
        [ngClass]="contactForm.get('contactNumber')?.touched && contactForm.get('contactNumber')?.invalid ? 'is-invalid': ''">
      <div class="text-danger">
        <small
          *ngIf="contactForm.get('contactNumber')?.touched && contactForm.get('contactNumber')?.hasError('required')">
          {{language.contactNumber + " " +language.isRequired}}
        </small>
        <small
          *ngIf="contactForm.get('contactNumber')?.touched && contactForm.get('contactNumber')?.hasError('pattern')">
          {{language.pleaseEnterValid + " " +language.contactNumber}}
        </small>
      </div>
    </div>

    <div class="mb-3">
      <label for="emailAddress" class="form-label col-form-label-sm mb-1 fw-semibold">{{language.emailAddress}}*</label>
      <input type="email" class="form-control form-control-sm bg-light" id="emailAddress" formControlName="emailAddress"
        [ngClass]="contactForm.get('emailAddress')?.touched && contactForm.get('emailAddress')?.invalid ? 'is-invalid': ''">
      <div class="text-danger">
        <small
          *ngIf="contactForm.get('emailAddress')?.touched && contactForm.get('emailAddress')?.hasError('required')">
          {{language.emailAddress + " " +language.isRequired}}
        </small>
        <small *ngIf="contactForm.get('emailAddress')?.touched && contactForm.get('emailAddress')?.hasError('pattern')">
          {{language.pleaseEnterValid + " " +language.emailAddress}}
        </small>
      </div>
    </div>

    <div class="mb-3">
      <label for="businessName" class="form-label col-form-label-sm mb-1 fw-semibold">{{language.businessName}}</label>
      <input trim type="text" class="form-control form-control-sm bg-light" id="businessName" formControlName="businessName"
        [ngClass]="contactForm.get('businessName')?.touched && contactForm.get('businessName')?.invalid ? 'is-invalid': ''">
      <div class="text-danger">
        <small *ngIf="contactForm.get('businessName')?.touched && (contactForm.get('businessName')?.hasError('minlength') || 
          contactForm.get('businessName')?.hasError('maxlength'))">
          {{language.businessName + ' ' + language.mustBe + ' ' + language.minimum + ' 5 ' + language.and + ' ' +
          language.maximum + ' 50 ' + language.characters }}
        </small>
      </div>
    </div>

    <div class="mb-3">
      <label for="projectDes"
        class="form-label col-form-label-sm mb-1 fw-semibold">{{language.descriptionAboutProject}}*</label>
      <textarea class="form-control form-control-sm bg-light" id="projectDes" rows="3" formControlName="projectDesc"
        [ngClass]="contactForm.get('projectDesc')?.touched && contactForm.get('projectDesc')?.invalid ? 'is-invalid': ''"></textarea>
      <div class="text-danger">
        <small *ngIf="contactForm.get('projectDesc')?.touched && contactForm.get('projectDesc')?.hasError('required')">
          {{language.projectDescription + " " +language.isRequired}}
        </small>
        <small *ngIf="contactForm.get('projectDesc')?.touched && (contactForm.get('projectDesc')?.hasError('minlength') || 
          contactForm.get('projectDesc')?.hasError('maxlength'))">
          {{language.projectDescription + ' ' + language.mustBe + ' ' + language.minimum + ' 5 ' + language.and + ' ' +
          language.maximum + ' 100 ' + language.characters }}
        </small>
      </div>
    </div>

    <div class="mb-3 d-flex justify-content-between align-items-center">
      <label class="form-label col-form-label-sm mb-1 fw-semibold">
        {{ language.startupsOrSoleProprietorships }}
      </label>
      <label class="switch">
        <input type="checkbox" [checked]="isDiscounted" disabled>
        <span class="slider" [attr.data-before-content]="isDiscounted ? language.yes : language.no"></span>
      </label>
    </div>

    <div class="pdf-container col-7 my-4 rounded d-flex align-items-center rounded-3">
      <div class="pdf-thumbnail p-2">
        <img src="../../../assets/images/quotation-image.svg" alt="pdf_thumbnail" width="100%" height="100%">
      </div>
      <div class="pdf-details ms-2">
        <span class="fw-semibold">{{language.projectQuotation}}</span><br>
        <div class="d-flex align-items-center color-primary">
          <small>PDF</small>
          <small class="span-primary px-2">&#x25CF;</small>
          <small>5.3MB</small>
        </div>
      </div>
    </div>

    <!-- <div class="my-3 py-3">
        <button class="btn btn-primary rounded-2 w-100 fw-bold" type="submit"
            [disabled]="contactForm.invalid">Send Quotation</button>
    </div> -->
  </form>
</div>

<div class="modal-foot">
  <button type="button" class="btn btn-primary rounded-2 w-100" [disabled]="contactForm.invalid"
    (click)="onSubmit('CONFIRM')">{{language.sendQuotation}}</button>
</div>