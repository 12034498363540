<div class="action-container">
  <button class="button-container" (click)="saveQuotation()" [disabled]="isSaveBtnDisabled">
    <div class="icon-container">
      <img src="../assets/svgs/download.svg" alt="">
    </div>
    <div class="text-container">
      <div class="sub-text ">{{language.saveYourQuotation}}</div>
      <div class="main-text bold primary-text">{{language.downloadPDF}}</div>
    </div>
  </button>
  <button class="button-container" (click)="finalizeQuotation()" [disabled]="isFinalizeBtnDisabled">
    <div class="icon-container">
      <img src="../assets/svgs/message.svg" alt="">
    </div>
    <div class="text-container">
      <div class="sub-text">{{language.finalizedYourPodTeam}}</div>
      <div class="main-text primary-text">{{language.talkWithTheTeam}}</div>
    </div>
  </button>
</div>