import { Directive, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trim]'
})
export class TrimDirective {

  constructor(@Optional() private ngControl: NgControl) { }

  @HostListener('blur', [
    '$event.target',
    '$event.target.value',
  ])
  onBlur(el: any, value: string): void {
    if ('function' === typeof value.trim && value.trim().replace(/\s\s+/g, ' ') !== value) {
      el.value = value.trim().replace(/\s\s+/g, ' ');
      const event = new Event("input");
      el.dispatchEvent(event);
    }
  }
}
