import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceAllocaionComponent } from './resource-allocaion/resource-allocaion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoOfDaysPopupComponent } from './no-of-days-popup/no-of-days-popup.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { InfoPopupComponent } from './info-popup/info-popup.component';
import { TrimDirective } from '../directives/trim.directive';

@NgModule({
  declarations: [
    ResourceAllocaionComponent,
    NoOfDaysPopupComponent,
    ContactFormComponent,
    InfoPopupComponent,
    TrimDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class PopupFacadeModule { }
