export class Position {
  name = '';
  qty = 1;
  no_of_days = 1;
  qty_x_days = 1;
  amount = 0;
  base_price = 0;
  hours = 0;

  constructor(json?: any) {
    if (json) {
      this.name = json.name;
      this.qty = json.qty;
      this.no_of_days = json.no_of_days;
      this.qty_x_days = json.qty_x_days;
      this.hours = json.hours;
      this.amount = json.price.amount * this.no_of_days * this.qty * this.hours;
      this.base_price = json.price.amount;
    }
  }

  getSendableJson(): any {
    return {
      "name": this.name,
      "qty": this.qty,
      "no_of_days": this.no_of_days,
      "qty_x_days": this.qty_x_days,
      "amount": this.amount
    }
  }
}
