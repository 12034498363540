import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderEvent: EventEmitter<LoaderEvent> = new EventEmitter();

  public eventListener() {
    return this.loaderEvent;
  }

  public show(message?: string) {
    this.loaderEvent.emit({ show: true, msg: message });
  }

  public hide() {
    this.loaderEvent.emit({ show: false });
  }

}

interface LoaderEvent {
  show: boolean, msg?: string | undefined
};
