<div class="main-container">
  <div class="header-container">
    <app-header (languageChanged)="onLanguageChange($event)" [isSwSelected]="isSwSelected"
      [language]="language.infoPopup"></app-header>
  </div>

  <div class="title-desc">
    <h3>{{language.homeHeading}}</h3>
    <p class="">{{language.homeDescription}}</p>
  </div>

  <div class="main-row">
    <div class="main-column">
      <app-team-list-component [resourcesList]="rawDataSet" [teamList]="teamList" [language]="language"
        (teamListChange)="onTeamListChange($event)"></app-team-list-component>
    </div>
    <div class="main-column flex-column">
      <div class="cost-table">
        <app-cost-table (moveCategory)="moveCategory($event)" (summary)="summary($event)" [dataSet]="teamList"
          [language]="language" [hours]="no_of_hours" (isDiscounted)="discount($event)"></app-cost-table>
      </div>
      <div class="action-btn">
        <app-action-buttons (onSaveClick)="saveQuotation($event)" (onFinalizeClick)="finalzeTeam($event)"
          [language]="language" [isSaveBtnDisabled]=" isSaveDisabled()" [isFinalizeBtnDisabled]='isFinalizeDisabled()'>
        </app-action-buttons>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
  <app-loader></app-loader>

  <div class="footer-container">
    <app-footer [language]="language"></app-footer>
  </div>
</div>