import { Language } from "./language.model";

export class Stack {
  id = '';
  top_category_id = '';
  name = '';
  description = '';
  status = '';
  created_at: string | null = null;
  updated_at: string | null = null;
  language_categories: Array<Language> = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.top_category_id = json.top_category_id;
      this.name = json.name;
      this.description = json.description;
      this.status = json.status;
      this.created_at = json.created_at;
      this.updated_at = json.updated_at;
      this.language_categories = (json.language_categories || []).map((languageData: any) => new Language(languageData));
    }
  }
}
